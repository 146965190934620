import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/default-page-layout.js";
import croppedPhoto from '../images/MAX_BLANK_200.png';
import uncroppedPhoto from '../images/MAX_DARK_200.png';
import croppedPhotoFull from '../images/MAX_BLANK.png';
import uncroppedPhotoFull from '../images/MAX_DARK.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "photos",
      "style": {
        "position": "relative"
      }
    }}>{`Photos`}<a parentName="h2" {...{
        "href": "#photos",
        "aria-label": "photos permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h2>
    <blockquote>
      <p parentName="blockquote">{`Clic droit pour enregistrer celle que vous voulez`}</p>
    </blockquote>

    <div className="flex justify-around text-center">
    <div>
        <img alt="Photo transparente de Maxime Richard" src={croppedPhoto} />
        <small>
            Photo{' '}
            <a className="text-underline text-blue-500 hover:text-blue-700" href={croppedPhotoFull} rel="nofollow noopener noreferrer" target="_blank">
                original
            </a>{' '}
            transparente de Maxime
        </small>
    </div>
    <div>
        <img alt="Photo foncée de Maxime Richard" src={uncroppedPhoto} />
        <small>
            Photo{' '}
            <a className="text-underline text-blue-500 hover:text-blue-700" href={uncroppedPhotoFull} rel="nofollow noopener noreferrer" target="_blank">
                original
            </a>{' '}
            foncée de Maxime
        </small>
    </div>
    </div>
    <h2 {...{
      "id": "biographie",
      "style": {
        "position": "relative"
      }
    }}>{`Biographie`}<a parentName="h2" {...{
        "href": "#biographie",
        "aria-label": "biographie permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h2>
    <h3 {...{
      "id": "présentation-complète-1ère-pers",
      "style": {
        "position": "relative"
      }
    }}>{`Présentation complète (1ère pers)`}<a parentName="h3" {...{
        "href": "#pr%C3%A9sentation-compl%C3%A8te-1%C3%A8re-pers",
        "aria-label": "présentation complète 1ère pers permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h3>
    <p>{`Je m'appelle Maxime Richard, je suis développeur full stack chez `}<a parentName="p" {...{
        "href": "https://www.tint.ai/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Tint`}</a>{`. Passionné par `}<a parentName="p" {...{
        "href": "https://github.com/zyhou/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`l'open source`}</a>{`, la personnalisation de Linux et les claviers mécaniques, je suis spécialisé dans la création rapide d'applications web. Aujourd'hui, je gère le `}<a parentName="p" {...{
        "href": "https://aperowebnancy.netlify.app/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Meetup Apéro Web`}</a>{` à Nancy. Habitué des bars nancéiens, j'adore parler de javascript et React autour d'une IPA.`}</p>
    <h3 {...{
      "id": "présentation-complète-3ère-pers",
      "style": {
        "position": "relative"
      }
    }}>{`Présentation complète (3ère pers)`}<a parentName="h3" {...{
        "href": "#pr%C3%A9sentation-compl%C3%A8te-3%C3%A8re-pers",
        "aria-label": "présentation complète 3ère pers permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h3>
    <p>{`Maxime Richard est développeur full stack chez `}<a parentName="p" {...{
        "href": "https://www.tint.ai/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Tint`}</a>{`. Passionné par `}<a parentName="p" {...{
        "href": "https://github.com/zyhou/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`l'open source`}</a>{`, la personnalisation de Linux et les claviers mécaniques, il est spécialisé dans la création rapide d'applications web. Aujourd'hui, Maxime gère le `}<a parentName="p" {...{
        "href": "https://aperowebnancy.netlify.app/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Meetup Apéro Web`}</a>{` à Nancy. Habitué des bars nancéiens, il adore parler de javascript et React autour d'une IPA.`}</p>
    <h3 {...{
      "id": "présentation-courte-1ère-pers",
      "style": {
        "position": "relative"
      }
    }}>{`Présentation courte (1ère pers)`}<a parentName="h3" {...{
        "href": "#pr%C3%A9sentation-courte-1%C3%A8re-pers",
        "aria-label": "présentation courte 1ère pers permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h3>
    <p>{`Je m'appelle Maxime Richard, je suis développeur full stack chez `}<a parentName="p" {...{
        "href": "https://www.tint.ai/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Tint`}</a>{`. Passionné par `}<a parentName="p" {...{
        "href": "https://github.com/zyhou/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`l'open source`}</a>{`, la personnalisation de Linux et les claviers mécaniques, je suis spécialisé dans la création rapide d'applications web.`}</p>
    <h3 {...{
      "id": "présentation-courte-3ère-pers",
      "style": {
        "position": "relative"
      }
    }}>{`Présentation courte (3ère pers)`}<a parentName="h3" {...{
        "href": "#pr%C3%A9sentation-courte-3%C3%A8re-pers",
        "aria-label": "présentation courte 3ère pers permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "fill": "currentcolor",
          "width": "24",
          "height": "24",
          "xmlns": "http://www.w3.org/2000/svg"
        }}><g parentName="svg"><path parentName="g" {...{
              "stroke": "null",
              "d": "M22.93 8.784v-3.07h-4.073l.94-4.3L16.664.8l-1.097 4.913h-4.699l1.097-4.299L8.832.8 7.735 5.713H2.566v3.07H7.11l-1.41 6.142H1v3.071h4.073l-.94 4.3 3.133.613 1.096-4.913h4.7l-1.097 4.3 3.133.613 1.096-4.913h5.17v-3.07H16.82l1.41-6.142h4.698zm-9.399 6.141H8.832l1.566-6.141h4.7l-1.567 6.141z"
            }}></path></g></svg></a></h3>
    <p>{`Maxime Richard est développeur full stack chez `}<a parentName="p" {...{
        "href": "https://www.tint.ai/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Tint`}</a>{`. Passionné par `}<a parentName="p" {...{
        "href": "https://github.com/zyhou/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`l'open source`}</a>{`, la personnalisation de Linux et les claviers mécaniques, il est spécialisé dans la création rapide d'applications web.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      